<template>
    <div>
        <div class="pay-ing" v-if="payStatus===1">
            支付中...
        </div>
        <div class="pay-state-page" v-else>
            <div class="title-box">
                <img class="back-icon" src="@/assets/common/icon_back.svg" @click="backToFloat">
                <div class="title-box-text">
                    {{payStatus===2?'支付成功':'支付失败'}}
                </div>
                <div class="back-icon"></div>
            </div>
            <div class="order-info-box">
                <div class="pay-success" v-if="payStatus==2">
                    <div class="game-name">
                        平台币*{{orderInfo.order_money}}
                    </div>
                    <div class="order-money-box">
                        <span class="money-icon">￥</span>
                        <span class="money">{{orderInfo.order_money}}</span>
                    </div>
                </div>
                <div class="pay-faild" v-else>
                    <img src="@/assets/pay/pay_faild_icon.svg" class="pay-faild-icon">
                    <div class="faild-text">
                        抱歉，您支付失败
                    </div>
                </div>
            </div>
            <div class="back-btn-box">
                <div class="back-btn" @click="backToGame">
                    返回游戏
                </div> 
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            payStatus:Number,
            orderInfo:Object
        },
        data() {
            return {
                
            }
        },
        methods:{
            backToFloat() {
                this.$emit('backToFloat')
            },
            backToGame() {
                this.$emit('backToGame')
            }
        }
    }
</script>

<style scoped>
    .pay-ing {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #333333;
    }
    .pay-state-page {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .title-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 8px 110px 8px;
    }
    .back-icon {
        width: 24px;
    }
    .title-box-text {
        flex: 1;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
    }
    .order-info-box {
        flex: 1;
    }
    .order-info-box>div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .game-name {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        padding-bottom: 12px;
        text-align: center;
    }
    .order-money-box {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 20px;
    }
    .money-icon {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
    }
    .money {
        font-size: 34px;
        font-weight: bold;
        color: #000000;
        line-height: 22px;
    }
    .pay-faild-icon {
        width: 51px;
    }
    .faild-text {
        padding-top: 19px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
    }
    .back-btn-box {
        padding-bottom: 80px;
    }
    .back-btn {
        width: 280px;
        height: 40px;
        background: #00C9D0;
        border-radius: 6px 6px 6px 6px;
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>