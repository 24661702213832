<template>
    <div class="payreturn-page">
        <Horizontal v-if="$store.getters.currentPageVersion === 'Horizontal'" 
            :payStatus="payStatus" :orderInfo="orderInfo" @backToFloat="backToFloat" @backToGame="backToGame"></Horizontal>
        <Vertical :payStatus="payStatus" :orderInfo="orderInfo" @backToFloat="backToFloat" @backToGame="backToGame" v-else ></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Common/Horizontal/platformRechageReturn.vue'
    import Vertical from '@/components/Common/Vertical/platformRechageReturn.vue'

    export default {
        components: {
            Horizontal,
            Vertical
        },
        data() {
            return {
                payStatus:1,
                requestTimeLimit:10,
                requestNum:1,
                orderInfo:{
                    game_name:'平台币充值',
                    order_money:'0.00'
                },
                isGetAllParams:false
            }
        },
        created() {
            
           this.getRequestData()
        },
        updated() {
          this.$common.screenOrientation()  
        },
        methods: {
            //获取支付状态
            getPayState() {
                let requestData = {
                        user_id:this.$store.state.userId,
                        token:this.$store.state.token,
                        order_id:this.$route.params.order_id || ""
                    }
                this.$api.pay.getPayState(requestData).then(res=> {
                    if(res.data.code === 200) {
                        this.payStatus = res.data.data.pay_status
                        if(this.payStatus == 2) {
                            this.orderInfo.order_money = (Number(res.data.data.order_money)/100).toFixed(2)
                            return
                        }
                        this.requestNum = this.requestNum+1
                        if(this.requestNum > this.requestTimeLimit) {
                            this.payStatus = 3
                        }else {
                            setTimeout(()=> {
                                this.getPayState()
                            },1000)
                        }
                        
                    }else {
                        this.payStatus = 3
                        clearInterval(this.payStateTimer)
                        this.$Toast({
                       	message: res.data.msg,
                       })
                    }
                })
            },
            //返回悬浮球的平台币页面
            backToFloat() {
                switch (this.$common.getNowSystem()){
                    case 'web':
                    	this.$common.postMessageToWindowParent('backFloat', null)
                        return
                    default:
                        this.$router.push({
                            name:'PlatformCoinIndex'
                        })
                    	break;
                }
                
            },
            //返回游戏
            backToGame() {
                switch (this.$common.getNowSystem()) {
                	case 'ios':
                		this.$common.postMessageToIos('closeNativePayment', null)
                        return
                	case 'Android':
                		android.closeNativePayment()
                		break;
                	default:
                        this.$common.postMessageToWindowParent('backGame', 'null')
                		console.log('web')
                		break;
                }
            },
            getRequestData() {
             let extInfo = {},
                     token = null,
                     userId = null
                 switch (this.$common.getNowSystem()) {
                 	case 'ios':
                 		this.$common.postMessageToIos('getExtInfo', null)
                         this.$common.postMessageToIos('getUserToken', null)
                         this.$common.postMessageToIos('getUserId', null)
                         return
                 	case 'Android':
                 		 extInfo = android.getExtInfo()
                         token = android.getUserToken()
                         userId = android.getUserId()
                         console.log('userId:'+ userId)
                 		break;
                 	default:
                 		window.addEventListener('message', this.listenerFunction)
                 		this.$common.postMessageToWindowParent('getExtInfo', null)
                 		this.$common.postMessageToWindowParent('getUserToken', null)
                 		this.$common.postMessageToWindowParent('getUserId', null)
                        setTimeout(()=> {
                            if(this.$store.state.extInfo === null || this.$store.state.token === null || this.$store.state.userId === null) {
                                //长时间未获取到参数
                                this.payStatus = 3
                                 this.$Toast({
                                	message: '参数获取失败',
                                })
                            }
                        },15000)
                 		return
                 }
                 this.saveRequestParame(extInfo, token, userId)
             },
             listenerFunction(e) {
                 console.log(e)
                 let that = this
                 switch (e.data.gsFloatEventType) {
                     case 'userTokenCallback':
                        that.saveRequestParame(null, e.data.data, null)
                        break
                     case 'extInfoCallback':
                        that.saveRequestParame(JSON.stringify(e.data.data), null, null)
                        break
                     case "userIdCallback":
                        that.saveRequestParame(null, null, e.data.data)
                        break
                 }
             },
             saveRequestParame(extInfo, token, userId) {
                 if(this.isGetAllParams) {
                     return
                 }
                 if(extInfo) {
                     this.$store.commit('getExtInfo', extInfo)
                     // localStorage.setItem('extInfo', extInfo)
                 }
                 if(token) {
                     this.$store.commit('getToken', token)
                     // localStorage.setItem('token', token)
                 }
                 if(userId) {
                     console.log('userId:'+ userId)
                     this.$store.commit('getUserId', userId)
                     // localStorage.setItem('userId', userId)
                 }
                 if(this.$store.state.extInfo !== null && this.$store.state.token !== null && this.$store.state.userId !== null) {
                     //执行获取完请求参数后的流程
                     this.isGetAllParams = true
                     this.getPayState()
                 }
                
             },
             //提供给native调用的方法
             iosGetUserTokenCallback(data) {
             	this.saveRequestParame(null, data, null)
             },
             iosGetExtInfoCallback(data) {
             	this.saveRequestParame(data, null, null)
             },
             iosGetMiniIdCallback(data) {
             	this.saveRequestParame(null, null, data)
             }
        },
        mounted() {
            if(typeof(window.iosGetUserTokenCallback) !== 'function') {
                window.iosGetUserTokenCallback = this.iosGetUserTokenCallback
                window.iosGetExtInfoCallback = this.iosGetExtInfoCallback
                window.iosGetMiniIdCallback = this.iosGetMiniIdCallback
            }
            this.$common.screenOrientation()  
        },
        beforeDestroy() {
           window.removeEventListener('message', this.listenerFunction) 
        }
    }
</script>

<style scoped>
    .payreturn-page {
        width: 100vw;
        height: 100vh;
        background-color: #ffffff;
    }
    .payreturn-page>div {
        width: 100%;
        height: 100%;
    }
</style>
